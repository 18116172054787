.select-usernft-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  
  .select-usernft-container .usernft-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
    grid-row-gap: 15px;
  }
  
  .usernft-grid .usernft-item {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-self: center;
    align-self: center;
  }
  
  .usernft-item img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .usernft-item .name-container {
    position: absolute;
    background-color: rgba(138,139,139,.5686274509803921);
    border-radius: 5px;
    margin: 10px;
  }
  
  .usernft-item .name-container p {
    margin: 0;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
  }
  
  .select-usernft-container .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
  }
  
  .select-usernft-container .loading .indicator {
    display: flex;
  }
  
  .select-usernft-container .loading .indicator p {
    font-weight: bold;
    font-size: 28px;
    padding-left: 5px;
  }
  
  .select-usernft-container .loading img {
    width: 450px;
    padding-top: 25px;
  }
  .nft-container{
    flex-wrap: wrap;
  }
  
  @media (max-width: 1000px) {
    .nft-container {
      flex: 100%!important;
      flex-direction: column!important;
    }
  }
  .players-container{
      padding: 0 15px;
  }
  .players-container .player {
    display: flex;
    flex-direction: column;
    max-height: 80%;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffc400;
    margin: 15px;
  }
  
  .player .image-content img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .players-container .active-players {
    display: flex;
    flex-direction: column;
  }
  
  .active-players .players-list {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: scroll;
  }
  
  .players-list .active-player-item {
    display: flex;
    align-items: center;
    background-color: #b12a5b;
    border-radius: 8px;
    margin: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .active-player-item .player-image {
    width: 64px;
    height: 64px;
    background-color: gray;
    margin-right: 15px;
    border-radius: 8px;
  }
  
  .active-player-item .player-content {
    flex-direction: column;
    text-align: left;
  }
  
  .players-container .attack-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .slick-slider{
    width: 80%;
  }

.slick-initialized .slick-slide {
  padding: 0 10px;
}
.slick-slide {

  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.slick-center img{
  -webkit-transform: scale(1.2);
  transform: scale(1.2);

}
.kave-btn {
	 position: relative;
	 border: solid 1px var(--primary);
	 background: none;
   color: var(--secondary);
	 font-family: "pacificacondensedregular";
	 text-transform: uppercase;
	 font-weight: normal;
	 letter-spacing: 1.8px;
	 width: 150px;
	 height: 60px;
	 padding: 0;
	 transition: all 0.25s ease;
	 outline: none;
}
 .kave-btn:before {
	 content: "";
	 position: absolute;
	 top: 0;
	 left: 10%;
	 width: 60px;
	 height: 1px;
	 transform: translateY(-1px);
	 background: var(--secondary);
	 transition: all 0.25s ease;
}
 .kave-btn:after {
	 content: "";
	 position: absolute;
	 bottom: 0;
	 right: 10%;
	 width: 60px;
	 height: 1px;
	 transform: translateY(1px);
	 background: var(--secondary);
	 transition: all 0.25s ease;
}
 .kave-btn:hover {
	 box-shadow: 1px 1px 8px var(--primary);
	 color: var(--primary);
	 text-shadow: 0 0 8px var(--primary);
}
 .kave-btn:hover.kave-btn:before {
	 left: 0;
	 width: 20px;
}
 .kave-btn:hover.kave-btn:after {
	 right: 0;
	 width: 20px;
}
 .kave-btn:hover .kave-line:before {
	 bottom: 0;
}
 .kave-btn:hover .kave-line:after {
	 top: 0;
}
 .kave-line:before {
	 content: "";
	 position: absolute;
	 bottom: 30%;
	 right: 0;
	 width: 1px;
	 height: 20px;
	 transform: translateX(1px);
	 background: var(--secondary);
	 transition: all 0.25s ease;
}
 .kave-line:after {
	 content: "";
	 position: absolute;
	 top: 30%;
	 left: 0;
	 width: 1px;
	 height: 20px;
	 transform: translateX(-1px);
	 background: var(--secondary);
	 transition: all 0.25s ease;
}

.slick-slide img {
  width: 70%!important;
}

@media screen and (max-width: 700px) {

  #video {
  display: none;
  }
  
  #container{
    background-image: url("/src/Mobile BG.png");
    background-size: cover;
    background-position: center;
  }
  
  .slick-slider{
    margin-top: 100px!important;
  }
  }